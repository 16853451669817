import { apiSlice } from '../apiSlice';
import { Shop, ShopVinculated } from '../../types/Shop';

const endpointUrl = '/shops';

export const initialState: Shop = {
  id: '',
  description: ''
};

function getShops() {
  return `${endpointUrl}`;
}

function getShopVinculated({ id }: { id: string }) {
  return `${endpointUrl}/${id}/vinculated`;
}

export const shopApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query }) => ({
    getAllShops: query<Shop[], void>({
      query: getShops,
      providesTags: ['Shops']
    }),
    getShopVinculated: query<ShopVinculated, { id: string }>({
      query: getShopVinculated
    })
  })
});

export const { useGetAllShopsQuery, useGetShopVinculatedQuery } = shopApiSlice;
