import { apiSlice } from '../apiSlice';
import { Agency } from '../../types/Agency';

const endpointUrl = '/agencies';

export const initialState: Agency[] = [];

function getAgencies() {
  return `${endpointUrl}`;
}

export const agencyApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query }) => ({
    getAllAgencies: query<Agency[], void>({
      query: getAgencies,
      providesTags: ['Agencies']
    })
  })
});

export const { useGetAllAgenciesQuery } = agencyApiSlice;

export default agencyApiSlice;
