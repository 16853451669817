import { apiSlice } from '../apiSlice';

const endpointUrl = '/bulks';

export const initialState: any[] = [];

function deleteBulk({ id }: { id: string }) {
  return {
    url: `${endpointUrl}/${id}`,
    method: 'DELETE'
  };
}

function getAllBulks() {
  return {
    url: `${endpointUrl}`,
    method: 'GET'
  };
}

export const bulkApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    deleteBulk: mutation<any, { id: string }>({
      query: deleteBulk,
      invalidatesTags: ['Bulks']
    }),

    getAllBulks: query<any[], void>({
      query: getAllBulks,
      providesTags: ['Bulks']
    }),
    uploadBulk: mutation<any, FormData>({
      query(data) {
        return {
          url: `${endpointUrl}`,
          method: 'POST',
          body: data,
          formData: true
        };
      },
      invalidatesTags: ['Bulks']
    })
  })
});

export const {
  useDeleteBulkMutation,
  useUploadBulkMutation,
  useGetAllBulksQuery
} = bulkApiSlice;

export default bulkApiSlice;
