import {
  Action,
  combineReducers,
  configureStore,
  PreloadedState,
  ThunkAction
} from '@reduxjs/toolkit';
import { apiSlice } from './apiSlice';
import { authSlice } from './slices/authSlice';
import { searchSlice } from './slices/searchSlice';
import { shopApiSlice } from './slices/shopSlice';
import { regionApiSlice } from './slices/regionSlice';
import { agencyApiSlice } from './slices/agencySlice';
import pictureApiSlice from './slices/pictureSlice';
import stateApiSlice from './slices/stateSlice';
import bulkApiSlice from './slices/bulkSlice';
const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  [regionApiSlice.reducerPath]: apiSlice.reducer,
  [shopApiSlice.reducerPath]: apiSlice.reducer,
  [agencyApiSlice.reducerPath]: apiSlice.reducer,
  [pictureApiSlice.reducerPath]: apiSlice.reducer,
  [stateApiSlice.reducerPath]: apiSlice.reducer,
  [bulkApiSlice.reducerPath]: apiSlice.reducer,
  auth: authSlice.reducer,
  search: searchSlice.reducer
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoreActions: true
        }
      }).concat(apiSlice.middleware)
  });
};
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
