import { apiSlice } from '../apiSlice';

const endpointUrl = '/records';

export const initialState: any[] = [];

function searchRecord({ queryString }) {
  return `${endpointUrl}/?${queryString}`;
}

function getRecord({ id }: { id: string }) {
  return `${endpointUrl}/${id}`;
}

function createRecord(record: any) {
  return {
    url: endpointUrl,
    method: 'POST',
    body: record
  };
}

function updateRecord(record) {
  return {
    url: `${endpointUrl}/${record.id}`,
    method: 'PUT',
    body: record
  };
}

function deleteRecord({ id }: { id: string }) {
  return {
    url: `${endpointUrl}/${id}`,
    method: 'DELETE'
  };
}

export const recordApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    searchRecord: query<any[], { queryString: string }>({
      query: searchRecord
    }),
    getRecord: query<any, { id: string }>({
      query: getRecord
    }),
    createRecord: mutation<{ id: string }, any>({
      query: createRecord,
      invalidatesTags: ['Records']
    }),
    updateRecord: mutation<any, any>({
      query: updateRecord,
      invalidatesTags: ['Records']
    }),
    deleteRecord: mutation<any, { id: string }>({
      query: deleteRecord,
      invalidatesTags: ['Records']
    })
  })
});

export const {
  useLazySearchRecordQuery,
  useLazyGetRecordQuery,
  useCreateRecordMutation,
  useUpdateRecordMutation,
  useDeleteRecordMutation
} = recordApiSlice;

export default recordApiSlice;
