import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

const baseUrl = `${process.env.REACT_APP_API}`;

export const apiSlice = createApi({
  reducerPath: 'api',
  tagTypes: [
    'Shops',
    'Regions',
    'Agencies',
    'Records',
    'Pictures',
    'States',
    'Bulks'
  ],
  endpoints: (builder) => ({}),
  baseQuery: customFetchBase
});
