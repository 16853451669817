import { Suspense, lazy } from 'react';

import SidebarLayout from 'src/layouts/SidebarLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { ProtectedRoute } from './security/ProtectedRoute';
import { AuthRoute } from './security/AuthRoute';

export interface RouteObject {
  path: string;
  element: JSX.Element;
  children?: RouteObjectChildren[];
}

export interface RouteObjectChildren {
  path: string;
  element: JSX.Element;
  isAdminOnly?: boolean;
}

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Home = Loader(lazy(() => import('src/content/pages/Home')));

const User = Loader(lazy(() => import('src/content/pages/User')));

const Record = Loader(lazy(() => import('src/content/pages/Record')));

const Search = Loader(lazy(() => import('src/content/pages/Search')));

const Detail = Loader(lazy(() => import('src/content/pages/Detail')));

const Blank = Loader(lazy(() => import('src/content/pages/Blank')));

const Bulk = Loader(lazy(() => import('src/content/pages/Bulk')));

const BulkReport = Loader(lazy(() => import('src/content/pages/BulkReport')));

const CallBack = Loader(lazy(() => import('src/content/pages/Callback/index')));

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Blank />
  },
  {
    path: '/callback',
    element: <CallBack />
  },
  {
    path: '/',
    element: (
      <AuthRoute>
        <SidebarLayout />
      </AuthRoute>
    ),
    children: [
      {
        path: 'home',
        element: <Home />
      },
      {
        path: 'users',
        element: <User />
      },
      {
        path: 'records',
        element: (
          <ProtectedRoute>
            <Record />
          </ProtectedRoute>
        )
      },
      {
        path: 'bulks',
        element: (
          <ProtectedRoute>
            <Bulk />
          </ProtectedRoute>
        )
      },
      {
        path: 'bulks-reports',
        element: (
          <ProtectedRoute>
            <BulkReport />
          </ProtectedRoute>
        )
      },
      {
        path: 'search/:id',
        element: <Detail />
      },
      {
        path: 'search',
        element: <Search />
      }
    ]
  }
];

export default routes;
