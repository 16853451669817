import { forwardRef, Ref, useState, ReactElement, useEffect } from 'react';
import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Dialog,
  DialogTitle,
  Slide,
  Autocomplete,
  Divider,
  CircularProgress,
  Grid
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import { ptBR } from 'date-fns/locale';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useGetAllRegionsQuery } from 'src/service/slices/regionSlice';
import { useGetAllShopsQuery } from 'src/service/slices/shopSlice';
import { useGetAllAgenciesQuery } from 'src/service/slices/agencySlice';
import { useLazySearchRecordQuery } from 'src/service/slices/recordSlice';
import { useGetAllStatesQuery } from 'src/service/slices/stateSlice';
import { format, isValid } from 'date-fns';
import { setRecords, setLoading } from 'src/service/slices/searchSlice';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);

const SearchInputWrapper = styled(TextField)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};

    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(17)};
    }
`
);

const DialogTitleWrapper = styled(DialogTitle)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
`
);

function HeaderSearch() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { data: regionResult } = useGetAllRegionsQuery();
  const { data: shopResult } = useGetAllShopsQuery();
  const { data: agencyResult } = useGetAllAgenciesQuery();
  const { data: stateResult } = useGetAllStatesQuery();
  const [searchRecord, { isFetching }] = useLazySearchRecordQuery();

  const [regionValue, setRegionValue] = useState(null);
  const [shopValue, setShopValue] = useState(null);
  const [agencyValue, setAgencyValue] = useState(null);
  const [recordAtValue, setRecordAtValue] = useState(null);
  const [stateValue, setStateValue] = useState(null);
  const [error, setError] = useState('');

  const [openAutoShop, setOpenAutoShop] = useState(false);
  const [openAutoRegion, setOpenAutoRegion] = useState(false);
  const [openAutoAgency, setOpenAutoAgency] = useState(false);
  const [openAutoState, setOpenAutoState] = useState(false);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(setLoading(isFetching));
  }, [isFetching]);

  const handleRegionChange = (event, value) => {
    setRegionValue(value);
    setOpenAutoRegion(false);
    setOpenAutoState(false);
  };

  const handleShopChange = (event, value) => {
    setShopValue(value);
    setOpenAutoShop(false);
    setOpenAutoState(false);
  };

  const handleStateChange = (event, value) => {
    setStateValue(value);
    setOpenAutoRegion(false);
    setOpenAutoShop(false);
    setOpenAutoAgency(false);
  };

  const handleAgencyChange = (event, value) => {
    setAgencyValue(value);
    setOpenAutoAgency(false);
    setOpenAutoState(false);
  };

  const handleDateChange = (date) => {
    if (date && isValid(date)) {
      setRecordAtValue(date);
      setError(null);
    } else {
      setError('Data Inválida');
      setRecordAtValue(null);
    }
  };

  const searchButton = async () => {
    let params = '';

    if (regionValue) params += `&region=${regionValue.id}`;

    if (shopValue) params += `&shop=${shopValue.id}`;

    if (agencyValue) params += `&agency=${agencyValue.id}`;

    if (stateValue) params += `&state=${stateValue.id}`;

    if (recordAtValue)
      params += `&recordAt=${format(recordAtValue, 'yyyy-MM-dd')}`;

    if (params.length == 0) return;

    const response = await searchRecord({ queryString: `${params}` });

    if (response.data.length == 0) {
      enqueueSnackbar('Não foram encontrados registros!', {
        variant: 'warning'
      });
      dispatch(setRecords([]));
      return;
    }

    if (response.data) {
      dispatch(setRecords(response.data));
      handleClose();
      enqueueSnackbar(`Foram encontrados ${response.data.length} registros.`, {
        variant: 'success'
      });
      navigate('/search');
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title="Search">
        <IconButton color="secondary" onClick={handleClickOpen}>
          <SearchTwoToneIcon />
        </IconButton>
      </Tooltip>

      <DialogWrapper
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
        scroll="paper"
        onClose={handleClose}
      >
        <DialogTitleWrapper>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mb={1}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              <Autocomplete
                open={openAutoShop}
                onOpen={() => setOpenAutoShop(true)}
                onClose={() => setOpenAutoShop(false)}
                value={shopValue}
                options={shopResult ? shopResult : []}
                onChange={handleShopChange}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                id="disable-close-on-select"
                disableCloseOnSelect
                renderInput={(params) => (
                  <SearchInputWrapper
                    {...params}
                    label="Lojas"
                    placeholder="Procure por lojas..."
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Autocomplete
                open={openAutoAgency}
                onOpen={() => setOpenAutoAgency(true)}
                onClose={() => setOpenAutoAgency(false)}
                value={agencyValue}
                onChange={handleAgencyChange}
                options={agencyResult ? agencyResult : []}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                id="disable-close-on-select"
                disableCloseOnSelect
                renderInput={(params) => (
                  <SearchInputWrapper
                    {...params}
                    label="Agência"
                    placeholder="Procure por agencia..."
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <Autocomplete
                open={openAutoRegion}
                onOpen={() => setOpenAutoRegion(true)}
                onClose={() => setOpenAutoRegion(false)}
                value={regionValue}
                onChange={handleRegionChange}
                options={regionResult ? regionResult : []}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                id="disable-close-on-select"
                disableCloseOnSelect
                renderInput={(params) => (
                  <SearchInputWrapper
                    {...params}
                    label="Regional"
                    placeholder="Procure por regional..."
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Autocomplete
                open={openAutoState}
                onOpen={() => setOpenAutoState(true)}
                onClose={() => setOpenAutoState(false)}
                value={stateValue}
                onChange={handleStateChange}
                options={stateResult ? stateResult : []}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                id="disable-close-on-select"
                disableCloseOnSelect
                renderInput={(params) => (
                  <SearchInputWrapper
                    {...params}
                    label="Estado"
                    placeholder="Procure por estado..."
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={3} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  value={recordAtValue}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <SearchInputWrapper
                      {...params}
                      fullWidth
                      error={!!error}
                      helperText={error}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogTitleWrapper>
        <Divider />
        <LoadingButton
          sx={{ margin: 1 }}
          loading={isFetching}
          variant="contained"
          color="primary"
          startIcon={<SearchTwoToneIcon />}
          onClick={searchButton}
          loadingIndicator={<CircularProgress size={24} color="primary" />}
        >
          {' '}
          Buscar{' '}
        </LoadingButton>
      </DialogWrapper>
    </>
  );
}

export default HeaderSearch;
