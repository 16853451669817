import { apiSlice } from '../apiSlice';
import { State } from '../../types/State';

const endpointUrl = '/states';

export const initialState: State[] = [];

function getStates() {
  return `${endpointUrl}`;
}

export const stateApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query }) => ({
    getAllStates: query<State[], void>({
      query: getStates,
      providesTags: ['States']
    })
  })
});

export const { useGetAllStatesQuery } = stateApiSlice;

export default stateApiSlice;
